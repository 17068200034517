<template>
  <div class="search-zone">
    <ul v-if="!admin" class="notices">
      <li lang="fr">FR : Saisissez les 13 chiffres à côté du code-barre ou la référence du produit dans la fenêtre ci-dessous :</li>
      <li lang="ua">UA : Введіть 13 цифр поруч із штрих-кодом або посилання на товар у вікні нижче:</li>
      <li lang="es">ES : Ingrese los 13 dígitos al lado del código de barras o la referencia del producto en la ventana a continuación :</li>
      <li lang="pt">PT : Digite os 13 dígitos ao lado do código de barras ou la referência do produto na janela abaixo :</li>
      <li lang="it">IT : Immettere le 13 cifre accanto al codice a barre o il riferimento del prodotto nella finestra seguente :</li>
      <li lang="el">EL : Εισαγάγετε τα 13 ψηφία δίπλα στον γραμμωτό κώδικα η αναφοράή προϊόντος στο παράθυρο που ακολουθεί :</li>
      <li lang="pl">PL : Wprowadź 13 cyfr obok kodu kreskowego lub numeru referencyjnego produktu w oknie poniżej:</li>
      <li lang="ro">RO : Introduceți cele 13 cifre de lângă codul de bare sau referinta produsului în fereastra de mai jos:</li>
      <li lang="en">EN : Enter the 13 digits next to the barcode or the product reference in the window below:</li>
    </ul>

    <div class="search-form">
      <label for="search">EAN / Reference :</label>
      <i class="icon-search"></i>
      <input class="searchField"
             id="search"
             name="search"
             v-model="searchString"
             placeholder="EAN/Reference"
             type="text"
             v-on:keyup="search()">
     <i class="icon-delete" aria-hidden="true"></i>
     <a v-on:click="search()">Search <span class="icon">&#8674;</span></a>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Search',
  props: {
    admin:Boolean
  },
  methods: {
    search () {
      this.$emit('searchValidated', this.searchString)
    }
  },
  data () {
    return {
      searchString: ''
    }
  }
}
</script>

<style scoped>
    .search-zone {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .searchbar {
        display:flex;
        flex-direction:row;
    }

    label {
      font-weight: bold;
      color: #495057;
      white-space: nowrap;
    }

    .search-form {
      padding: 15px 20px;

      background-color: #dee2e6;
      box-sizing: border-box;

      margin-top: 2px;
      font-size: 16px;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    .icon-search {
        color: #14A39F;
        padding-left: 20px;
    }

    .searchField {
        border: none;
        height: 35px;

        color: #495057;

        padding-left: 10px;
    }

    .notices {
      text-align: left;
      margin-left: auto;
      margin-right: auto;
      list-style: none;
      padding: 20px 0;
      color: #495057;
    }

    @media (min-width: 968px) {
      .searchField {
        min-width: 280px;
      }
    }
</style>
