<template>
    <div>
    <div v-html="page">

</div>
<div id="bottom-bar" marginwidth="0" marginheight="0">

<div id="menu">
    <ul>
        <li @click="displayEnglishDiv()">English</li>
        <li @click="displayFrenchDiv()">Français</li>
        <li @click="displaySpanishDiv()">Español</li>
    </ul>
</div>


</div>
    </div>


</template>

<script>

export default {
    name: 'AcHomePage',
    props: {
        page: Text
    },
    methods: {
        displayEnglishDiv(){
            document.querySelector("#english").style.display = "block"
            document.querySelector("#french").style.display = "none"
            document.querySelector("#spanish").style.display = "none"
            document.querySelector("html").lang="en"
        },
        displayFrenchDiv(){
            document.querySelector("#french").style.display = "block"
            document.querySelector("#english").style.display = "none"
            document.querySelector("#spanish").style.display = "none"
            document.querySelector("html").lang="fr"
        },
        displaySpanishDiv(){
            document.querySelector("#spanish").style.display = "block"
            document.querySelector("#french").style.display = "none"
            document.querySelector("#english").style.display = "none"
            document.querySelector("html").lang="es"
        },
    }
}
</script>

<style scoped>

#bottom-bar {
            position : fixed;
            bottom : 0;
            width: 100%;
        }
        #menu body, ul {
          margin: 0px;
          padding: 5px;
          background-color: rgb(128, 128, 128);
        }
        #menu ul li {
          display: inline;
          list-style-type: none;
          border-top: 1px solid black;
          border-left: 1px solid black;
          border: 1px solid black;
          font-weight: bold;
          background-color: white;
          padding: 2px;
          cursor: pointer;
        }
        #menu ul li:last-child {
          border: 1px solid black;
        }
</style>
