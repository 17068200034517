import Vue from "vue"
import VueRouter, { RouteConfig } from "vue-router"
import Home from "@/views/Home.vue"
import Admin from "@/views/Admin.vue"
import Ac from "@/views/Ac.vue"
import Heaters from "@/views/Heaters.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
  },
  {
    path: "/ac",
    name: "Ac",
    component: Ac,
  },
  {
    path: "/heaters",
    name: "Heaters",
    component: Heaters,
  }]

 const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

export default router
