<template>
    <div>
        <ul>
            <li v-for="file in files" :key="file" @click="downloadFile">
                <a :href="'/api/v1/heaters/downloadFile/'+file" target="_blank">{{ file }}</a>
            </li>
        
        </ul>
    </div>
</template>

<script>

export default {
    name: 'HeatersHomePage',
    props: {
        files: Array
    },
}

</script>

<style scoped>
div{
    text-align:left;
}
li {
    color: -webkit-link;
    cursor: pointer;
    text-decoration: underline;
}

a{
    color: -webkit-link;
    cursor: pointer;
    text-decoration: underline;
    transition:none;
    background-color: transparent;
    box-shadow: none;
    padding:initial;
    margin:auto;
    font-weight:normal;
}
a::before{
    transition:none;
    background-color: transparent;

}
a::after{
    transition:none;
    background-color: transparent;
}
</style>