<template>
  <form v-on:submit.prevent="onSubmit">
    <label for = "login">Login :</label>
    <input id="login" type="text" v-model="email"/>
    <label for="password">Password :</label>
    <input id="password" type="password" v-model="password"/>
    <button type="submit">Login</button>
  </form>
</template>

<script>
import { Component, Vue } from "vue-property-decorator"
import LoginService from "@/services/rest/LoginService"

export default @Component
class Login extends Vue {
  email = ""
  password = ""
  logginError = ""
  acceptCookie = true

  loginService = new LoginService();

  onSubmit() {
      console.log("performLogging")
      authService.login().then(() => {
      this.$emit('loggedIn', true)
    }).catch((errorMessage) => {
      console.log(errorMessage)
      this.logginError = errorMessage
    })
  }
}
</script>

<style scoped>

form {
  margin: 20px;
}

input {
  margin-left: 10px;
  margin-right: 10px;
}
</style>
