<template>
    <div>
    <AcHomePage v-bind:page="page"/>
    </div>
</template>

<script>
import AcHomePage from "@/components/AcHomePage"
import { Component, Vue } from "vue-property-decorator"

export default @Component ({
    components : {
        AcHomePage,
    }
})

class Ac extends Vue {

    page = ""

    created() {
    const apiURLRoot = window.location.protocol + "//" + window.location.host
    fetch(apiURLRoot + "/api/v1/ac")
    .then(response => response.text())
    .then(text => {
        this.page = text;
    }).catch(err => {
        console.log(err);
    });
  }

}

</script>