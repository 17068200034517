<template>
  <div class="row">
    <span v-if="!editionMode">{{innerFile.name}}</span>
    <select v-model="innerFile.name" v-if="editionMode">
      <option disabled value="">Please select one</option>
      <option>Notice</option>
      <option>Label</option>
      <option>Conformity</option>
      <option>Dimmers</option>
      <option>End-of-life</option>
      <option>Information</option>
      <option>Reparability</option>
    </select>
    <a v-if="!editionMode" :href="'api/v1/products/'+innerFile.file" target="_blank">Voir</a>
    <a v-if="!editionMode" v-on:click="deleteFile()">Supprimer</a>
    <input v-if="editionMode" id="file" type="file"  @change="changeFile">
    <a v-if="tempFile" v-on:click="save()">Envoyer</a>
    <span v-if="message">{{message}}</span>
    <span v-if="errorMessage">{{errorMessage}}</span>
  </div>
</template>

<script>
import { Prop, Component, Vue } from "vue-property-decorator"
import ProductService from "@/services/rest/ProductService"

export default @Component
class FileInput extends Vue {

  @Prop() file

  innerFile = null
  tempFile = null
  message = null
  errorMessage = null
  editionMode = true

  productService = new ProductService();

  created() {
    this.innerFile = {...this.file}
    this.editionMode = !this.innerFile.name
  }

  changeFile(event) {
    this.tempFile = event.target.files[0];
  }

  async save() {
    if (this.tempFile) {
      try {
        if(!this.innerFile.name){
          throw "Merci de choisir le type de fichier"
        }
        this.errorMessage = null
        this.message = "Uploading..."
        await this.productService.updateFile(this.tempFile.name, this.tempFile)
        this.$emit('fileChanged', this.file, {name: this.innerFile.name, file:this.tempFile.name})
        this.message = null
        this.tempFile = null
      } catch (e) {
        this.message = null
        this.errorMessage = e
      }
    }
  }

  deleteFile() {
    this.$emit('fileDeleted', this.file)
  }

}
</script>

<style scoped>
</style>
