<template>
  <div>
    <a v-if="!importing" v-on:click="startImport()">Import products</a>
    <form v-if="importing">
        <label for="file">Fichier :</label>
        <input id="file" type="file" accept=".zip"  @change="changeFile">
        <a v-on:click="importFile()">Import</a>
        <a v-on:click="cancel()">Cancel</a>
    </form>
    <div>
      <span v-if="message !== null">{{message}}</span>
      <span v-if="errorMessage !== null">{{errorMessage}}</span>
    </div>

  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator"
import ProductService from "@/services/rest/ProductService"

export default @Component
class MassImport extends Vue {

  importing = false
  file = null

  message = null
  errorMessage = null

  productService = new ProductService();

  startImport() {
    this.importing = true
  }

  changeFile(event) {
      this.file = event.target.files[0];
  }

  async importFile() {
    if (this.file) {
      try {
        this.errorMessage = null
        await this.productService.massImport(this.file)
        this.message = "Import success"
        this.importing = false
        this.$emit('imported')
        setTimeout(() => {
          this.message = null
        }, 3000)
      } catch (e) {
        this.errorMessage = e.message
      }
    }
  }

  cancel() {
    this.message = null
    this.errorMessage = null
    this.importing = false
  }

}
</script>

<style scoped>
div {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
