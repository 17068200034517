<template>
    <div>
        <HeatersHomePage :files="files"/>
    </div>
</template>

<script>
import HeatersHomePage from "../components/HeatersHomePage.vue";
import { Component, Vue } from "vue-property-decorator"

export default @Component ({
    components: {
        HeatersHomePage
    }
})

class Heaters extends Vue {

    files=[]

    created(){
        const apiURLRoot = window.location.protocol + "//" + window.location.host
        fetch(apiURLRoot + "/api/v1/heaters/getFileNames")
        .then(response => {
            return response.json()
        })
        .then(jsonBody => {
            this.files = jsonBody;
        }).catch(err => {
        console.log(err);
        });
    }
}

</script>