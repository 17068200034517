<template>
  <div>
      <h1>Product : {{innerProduct.ean}} - {{innerProduct.ref}}</h1>

      <form>
        <div class="row">
          <label for="name">Ean :</label>
          <input id="name" type="text" v-model="innerProduct.ean" :disabled="!creation">
        </div>
        <div class="row">
          <label for="name">Reference :</label>
          <input id="name" type="text" v-model="innerProduct.ref" :disabled="!creation">
        </div>
        <div class="row">
          <label for="name">Name :</label>
          <input id="name" type="text" v-model="innerProduct.name">
        </div>
        <PhotoInput v-bind:photo="innerProduct.photo" @photoChanged="updatePhoto" @photoDeleted="deletePhoto"></PhotoInput>
        <div class="row">
          <span class="label">Files :</span> <a v-on:click="addFile">Ajouter</a>
        </div>
        <ul class="files-list">
          <li v-for="file in innerProduct.files" :key="file.file">
            <FileInput v-bind:file="file" @fileDeleted="deleteFile" @fileChanged="updateFile"/>
          </li>
        </ul>
      </form>

      <a v-on:click="back()"><span class="icon">&#8672;</span> Back</a>
      <a v-on:click="save()"><span class="icon">&#10003;</span> Save</a>
      <span v-if="message">{{message}}</span>
      <span v-if="errorMessage">{{errorMessage}}</span>

  </div>
</template>

<script>
import { Prop, Component, Vue } from "vue-property-decorator"
import PhotoInput from "@/components/PhotoInput"
import FileInput from "@/components/FileInput"
import ProductService from "@/services/rest/ProductService"

export default @Component({
  components: {
    PhotoInput,
    FileInput,
  },
})
class ProductEdition extends Vue {

  @Prop({ default: {ean:"", ref:"", photo:"", files:[]} }) item

  creation = false
  innerProduct = null
  errorMessage = null
  message = null
  productService = new ProductService()

  created() {
    this.innerProduct = this.item
    if (!this.item.ean) {
      this.creation = true
    }
  }

  back() {
    this.deleteUnsavedFiles()
    this.$emit('back')
  }

  deleteUnsavedFiles() {
    this.innerProduct.files = this.innerProduct.files.filter((file) => !!file.file)
  }

  updateFile(old, newValue) {
    Vue.set(this.innerProduct.files, this.innerProduct.files.findIndex(f => f.file === old.file), newValue)
  }

  deleteFile(file) {
    this.innerProduct.files = this.innerProduct.files.filter((value) => file.file !== value.file);
  }

  updatePhoto(newPhoto){
    this.innerProduct.photo=newPhoto;
  }

  deletePhoto(){
    this.innerProduct.photo = "";
  }

  addFile () {
    this.innerProduct.files.push({name:null, file:null})
  }

  async save () {
    if (!this.creation) {
      try {
        this.errorMessage = null
        this.message = "Saving..."
        await this.productService.updateProduct(this.innerProduct)
        this.$emit('saved')
        this.message = null
      } catch (e) {
        this.message = null
        this.errorMessage = e.message
      }
    } else {
      try {
        this.errorMessage = null
        this.message = "Saving..."
        await this.productService.createProduct(this.innerProduct)
        this.$emit('saved')
        this.message = null
      } catch (e) {
        this.message = null
        this.errorMessage = e.message
      }
    }
  }

}
</script>

<style scoped>
  img {
    max-width: 100%;
  }

  .files-list {
    max-width: 800px;
    margin: auto;
  }

  .notices {
    max-width: 80%;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    list-style: none;
    padding: 20px 0;
    color: #495057;
  }

  .label {
    width: 100px;
    text-align: left;
  }

</style>
