<template>
  <div>
    <Header/>

    <Search @searchValidated="searchValidated" v-bind:admin="false" />

    <SearchResult v-if="!selectedItem"
                  v-bind:items="result"
                  v-bind:admin="false"
                  @itemSelected="itemSelected"/>

    <Product v-if="selectedItem"
             v-bind:item="selectedItem"
             @back="back"/>

  </div>
</template>

<script>
import Search from "@/components/Search"
import SearchResult from "@/components/SearchResult"
import Product from "@/components/Product"
import Header from "@/components/Header"
import { Component, Prop, Vue } from "vue-property-decorator"

export default @Component({
  components: {
    Search,
    SearchResult,
    Product,
    Header
  },
})
class Home extends Vue{

  result = []
  data = []
  selectedItem = null

  searchValidated (searchString) {
    this.selectedItem = null
    this.result = []

    Array.from(this.data).forEach(item => {
      if (item.ean.includes(searchString)) {
        this.result.push(item)
      }
      else if (item.ref && item.ref.includes(searchString)){
        this.result.push(item)
      }
    })
  }

  created() {
    const apiURLRoot = window.location.protocol + "//" + window.location.host
    fetch(apiURLRoot + "/api/v1/products")
    .then(response => response.json())
    .then(response => {
        this.data = response;
        this.result = this.data;
    }).catch(err => {
        console.log(err);
    });
  }

  itemSelected (item) {
    this.selectedItem = item
  }

  back () {
    this.selectedItem = null
  }

}
</script>

<style>
</style>
