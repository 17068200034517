<template>
  <div>
      <h1>Product : {{item.ean}} - {{item.ref}}</h1>

      <img v-if="item.photo" :src="'api/v1/products/'+item.photo" alt="Product photo" />

      <ul class="files-list">
          <li v-for="doc in this.item.files" :key="doc.name">
              <a :href="'api/v1/products/'+doc.file" target="_blank">{{doc.name}}</a>
          </li>
      </ul>

      <ul class="notices">
        <li v-if="notice">Notice : FR : Notice, EN : Instructions, ES : Manual, IT : Istruzioni, PT : Aviso,
        EL : Οδηγίες χρήσης, PL : Instrukcja, UA : Інструкція, RO : Instrucţiuni</li>

        <li v-if="conformity">Conformity : FR : Déclaration de conformité, EN : Declaration of Conformity,
        ES : Declaración de conformidad, IT : Dichiarazione di conformità, PT : Declaração de conformidade,
        EL : Δήλωση πιστότητας, PL : Deklaracja zgodności, UA : Декларація відповідності,
        RO : Declaraţie de conformitate</li>

        <li v-if="label">Label : FR : Label énergétique, EN : Energy label, ES : Etiqueta energética,
        IT : Etichetta energetica, PT : Etiqueta energética, EL : Ενεργειακή ετικέτα, PL : Etykieta energetyczna,
        UA : Маркування щодо енергоефективності, RO : Etichetă energetică</li>

        <li v-if="eol">End-of-life : FR : Consignes de fin de vie, EN : End-of-life instructions,
        ES : Indicaciones para el final de vida útil, IT : Istruzioni di fine vita,
        PT : Instruções para o fim de vida útil, EL : Οδηγίες για το τέλος του κύκλου ζωής,
        PL : Instrukcje dotyczące końca okresu użytkowania, UA : Рекомендації з утилізації,
        RO : Sfaturi pentru eliminarea produsului</li>

        <li v-if="dimmers">Dimmers : FR : Liste des dimmers compatibles, EN : List of compatible dimmers,
        ES : Lista de reguladores compatibles, IT : Elenco dei dimmer compatibili,
        PT : Lista dos reguladores de intensidades compatíveis, EL : Λίστα συμβατών ρυθμιστών έντασης φωτισμού,
        PL : Lista kompatybilnych ściemniaczy, UA : Список сумісних реостатів, RO : Lista programatoarelor compatibile</li>

        <li v-if="information">Information : FR : Fiche d'information produit, EN : Product information sheet,
        ES : Ficha de información de producto, IT : Scheda di informazioni sul prodotto,
        PT : Ficha de informação do produto, EL : Δελτίο πληροφοριών προϊόντος, PL : Karta informacyjna produktu,
        UA : Листок з інформацією про продукт, RO : Fişă de informaţii produs</li>

        <li v-if="reparability">Reparability : FR : Réparabilité, EN : Reparability, ES : Reparabilidad,
        IT : Riparabilità, PT : Reparabilidade, EL : Δυνατότητα επισκευής, PL : Możliwość naprawy,
        UA : Можливість ремонту, RO : Reparabilitate</li>
      </ul>

      <a v-on:click="back()"><span class="icon">&#8672;</span> Back</a>

  </div>
</template>

<script>
export default {
  name: 'Product',
  methods: {
    back () {
      this.$emit('back')
    }
  },
  beforeMount() {
    this.item.files.forEach(element => {
      switch (element.name) {
        case "Conformity":
          this.conformity = true;
          break;
        case "Notice":
          this.notice = true;
          break;
        case "Label":
          this.label = true;
          break;
        case "End-of-life":
          this.eol = true;
          break;
        case "Dimmers":
          this.dimmers = true;
          break;
        case "Information":
          this.information = true;
          break;
        case "Reparability":
          this.reparability = true;
          break;
      };
    });
  },
  props: {
    item: Object
  },
  data: function () {
    return {
      notice : false,
      conformity : false,
      label : false,
      eol : false,
      dimmers : false,
      information : false,
      reparability : false,
    }
  }
}
</script>

<style scoped>
  img {
    max-width: 100%;
  }

  .files-list {
    list-style: none;
    padding: 0;
    margin: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .files-list a {
    display: inline-block;
    margin-bottom: 15px;
    border: 1px solid #80775E;
    color: #80775E;
    text-decoration: none;
    padding: 10px 30px;
    background-color: transparent;
    box-shadow: none;
    border-radius: 5px;
    font-size: 20px;
    transition: all 0.5s ease;
  }
  .files-list a:hover {
    color: #80775E;
    border-color: #80775E;
    background-color: #A599771A;
  }
  .files-list a:before,
  .files-list a:after {
    content: none;
  }

  .notices {
    max-width: 80%;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    list-style: none;
    padding: 20px 0;
    color: #495057;
  }
</style>
