<template>
  <div id="app">
    <div class="main">
      <router-view />
    </div>

  </div>
</template>

<script>
import Header from "@/components/Header"
import { Component, Vue } from "vue-property-decorator"

export default @Component({
  components: {
    Header
  },
})
class App extends Vue{}
</script>

<style>
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin: 50px;
  }

  h1 {
    background-color: #eee;
    color: #495057;
    margin-top: 30px;
    margin-bottom: 0;
    padding: 10px 30px;
  }

  a {
    cursor: pointer;
    position: relative;
    margin-left: 20px;
    margin-right: 20px;
    padding: 10px;
    font-size: 16px;
    color: #FFF;
    z-index: 1;
    font-weight: bold;
    background-color: #b8ac84;
    color: #1a181d;
    border-radius: 3px;
    overflow: hidden;
    box-shadow: 1px 1px 3px #0002;
    transition: box-shadow 0.7s ease;
    white-space: nowrap;
  }

  .icon:first-child {
    margin-right: 5px;
  }
  .icon:last-child {
    margin-left: 5px;
  }

  .row {
    max-width: 800px;
    display: flex;
    justify-content: flex-start;
    margin: auto;
    margin-top : 10px;
    margin-bottom: 10px;
    align-items: baseline;
  }

  .row label {
    width: 100px;
    text-align: left;
  }

  @media (min-width: 968px) {
    a {
      background-color: transparent;
      padding: 10px 25px;
    }
    a:before,
    a:after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #b8ac84;
      border-radius: 3px;
      transition: width 0.5s ease;
      z-index: -1;
    }
    a:after {
      width: 0;
    }
    a:hover {
      box-shadow: 1px 3px 5px #0005;
      color: #b8ac84;
    }
    a:hover:after {
      width: 100%;
      background-color: #1a181d;
      color: #b8ac84;
      clip-path: polygon(0 0, 27px 100%, 100% 100%, 100% 0);
    }
  }

</style>
