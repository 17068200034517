<template>
  <div class="row">
    <label for="photo">Photo :</label>
    <a v-if="innerPhoto" :href="'api/v1/products/'+innerPhoto" target="_blank">Voir</a>
    <a v-if="innerPhoto" v-on:click="deletePhoto()">Supprimer</a>
    <input v-if="!innerPhoto" id="file" type="file" accept=".png,.jpg"  @change="changeFile">
    <a v-if="tempPhoto" v-on:click="savePhoto()">Envoyer</a>
    <span v-if="message">{{message}}</span>
    <span v-if="errorMessage">{{errorMessage}}</span>
  </div>
</template>

<script>
import { Prop, Component, Vue } from "vue-property-decorator"
import ProductService from "@/services/rest/ProductService"

export default @Component
class PhotoInput extends Vue {

  @Prop() photo

  innerPhoto = null
  tempPhoto = null
  message = null
  errorMessage = null

  productService = new ProductService();

  created() {
    this.innerPhoto = this.photo
  }

  changeFile(event) {
    console.log(event.target);
    this.tempPhoto = event.target.files[0];
  }

  async savePhoto() {
    if (this.tempPhoto) {
      try {
        this.errorMessage = null
        this.message = "Uploading"
        await this.productService.updateFile(this.tempPhoto.name, this.tempPhoto)
        this.message = null
        this.$emit('photoChanged', this.tempPhoto.name)
        this.innerPhoto = this.tempPhoto.name
        this.tempPhoto = null
      } catch (e) {
        this.message = null
        this.errorMessage = e
      }
    }
  }

  deletePhoto() {
    this.innerPhoto = null
    this.$emit('photoDeleted')
  }

}
</script>

<style scoped>
</style>
