import ConversionUtils from "@/services/ConversionUtils"

export default class ProductService {


  /**
   * Import a zip file containing csv and pdf files)
   * @param the zip file
   * @return true if import succeeds (reject otherwise)
   */
  async massImport(file) {

    const content = await ConversionUtils.convertFileToBase64String(file)
    const apiURLRoot = window.location.protocol + "//" + window.location.host
    const response = await fetch(apiURLRoot + '/api/v1/products/import', {
        method: 'POST',
        headers: {
          Accept: "application/json",
          "Content-Type": "text/plain",
        },
        body: content
      })

    if (response.status >= 200 && response.status <= 299) {
      return
    } else {
      const message = await response.json().then(body => body.error)
      throw Error(message)
    }

  }

    /**
     * Updates a product file (photo or notice/...)
     * @param the filename and the file content
     * @return true if import succeeds (reject otherwise)
     */
    async updateFile(filename, file) {

      const content = await ConversionUtils.convertFileToBase64String(file)
      const apiURLRoot = window.location.protocol + "//" + window.location.host
      const response = await fetch(apiURLRoot + '/api/v1/products/updateFile/' + filename, {
          method: 'POST',
          headers: {
            Accept: "application/json",
            "Content-Type": "text/plain",
          },
          body: content
        })

      if (response.status >= 200 && response.status <= 299) {
        return
      } else {
        const message = await response.json().then(body => body.error)
        throw Error(message)
      }

    }

    /**
     * Updates a product file (photo or notice/...)
     * @param the filename and the file content
     * @return true if import succeeds (reject otherwise)
     */
    async createProduct(product) {

      const apiURLRoot = window.location.protocol + "//" + window.location.host
      const response = await fetch(apiURLRoot + '/api/v1/products/', {
          method: 'POST',
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(product)
        })

      if (response.status >= 200 && response.status <= 299) {
        return
      } else {
        const message = await response.json().then(body => body.error)
        throw Error(message)
      }

    }

    /**
     * Updates a product file (photo or notice/...)
     * @param the filename and the file content
     * @return true if import succeeds (reject otherwise)
     */
    async updateProduct(product) {
      const apiURLRoot = window.location.protocol + "//" + window.location.host
      const response = await fetch(apiURLRoot + '/api/v1/products/' + product.ean, {
          method: 'PUT',
          credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(product)
        })

      if (response.status >= 200 && response.status <= 299) {
        return
      } else {
        const message = await response.json().then(body => body.error)
        throw Error(message)
      }

    }
}
