<template>
  <div>
    <h1 class="result-title">Results <a v-if="admin" v-on:click="newProduct">New product</a></h1>

    <table aria-describedby="result-title">
      <tr>
        <th scope="column">EAN</th>
        <th scope="column">Reference</th>
        <th scope="column">Access to product</th>
      </tr>

        <tr v-for="item in this.items" :key="item.EAN">
          <td>{{item.ean}}</td>
          <td>{{item.ref}}</td>
          <td>
            <a v-if="!admin" v-on:click="select(item)">View product <span class="icon">&#8674;</span></a>
            <a v-if="admin" v-on:click="select(item)">Edit product <span class="icon">&#8674;</span></a>
            <a v-if="admin" v-on:click="deleteItem(item)">Delete product</a>
          </td>
        </tr>

    </table>
  </div>
</template>

<script>
export default {
  name: 'SearchResult',
  methods: {
    select (item) {
      this.$emit('itemSelected', item)
    },
    deleteItem (item) {
      if (confirm("Are you sure you want to delete " + item.ean + " ?")) {
        this.$emit('deleteItem', item)
      }
    },
    newProduct() {
      this.$emit('newProduct')
    }
  },
  props: {
    items: Array,
    admin: {
      default: false,
      type: Boolean
    }
  }
}
</script>

<style scoped>

  table {
    width: 100%;
  }
  table tr th {
    padding: 10px 15px;
    background-color: #495057;
    color: #FFF;
  }
  table tr td {
    padding: 10px 15px;
    background: #49505705;
  }
  table tr:nth-child(2n+1) td {
    background: linear-gradient(to bottom, #49505709, #4950570F);
  }

  .result-title {
     display: flex;
     justify-content: center;
  }

</style>
