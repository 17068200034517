export default class LoginService {

  /**
   * Performs a login with the given credentials
   * @param credentials credentials to use for loggin in
   * @return true if login succeeds (reject otherwise)
   */
  async login(login, password) {
    const apiURLRoot = window.location.protocol + "//" + window.location.host

    const response = await fetch(apiURLRoot + '/api/v1/login', {
        method: 'GET',
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-Requested-With": "JavaScript",
        }
    })

    if (response.status == 499) {
        window.location.assign(apiURLRoot + '/api/v1/login');
    } else if (response.status >= 200 && response.status <= 299) {
      return
    } else {
      throw Error(response.statusText)
    }
  }

  /**
   * Logs out.
   */
  logout() {
      const apiURLRoot = window.location.protocol + "//" + window.location.host
    return fetch(apiURLRoot + '/api/v1/logout', {
        method: 'POST'
    })
  }
}
