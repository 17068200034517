<template>
  <div>
    <Header/>

    <div>
      <h1 class="administration-title">Administration <a type="submit" v-if="!loggedIn" @click="login()">Login</a> <a v-if="loggedIn" v-on:click="logout()">Logout</a> </h1>
    </div>

    <MassImport v-if="!selectedItem && loggedIn"
                @imported="updateItems"/>

    <Search @searchValidated="searchValidated" v-bind:admin="true" v-if="!selectedItem && loggedIn"/>

    <!-- bouton de création et import de masse -->

    <SearchResult v-if="!selectedItem && loggedIn"
                  v-bind:items="result"
                  v-bind:admin="true"
                  @itemSelected="itemSelected"
                  @deleteItem="deleteItem"
                  @newProduct="newProduct"/>

    <ProductEdition v-if="selectedItem"
             v-bind:item="selectedItem"
             @back="back"
             @saved="itemSaved"/>

  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator"
import Login from "@/components/Login"
import MassImport from "@/components/MassImport"
import ProductEdition from "@/components/ProductEdition"
import Search from "@/components/Search"
import SearchResult from "@/components/SearchResult"
import LoginService from "@/services/rest/LoginService"
import Header from "@/components/Header"

export default @Component({
  components: {
    Login,
    ProductEdition,
    Search,
    SearchResult,
    MassImport,
    Header,
  },
})
class Admin extends Vue {

  result = []
  data = []
  selectedItem = null
  loggedIn = false

  loginService = new LoginService()

  searchValidated (searchString) {
    this.selectedItem = null
    this.result = []

    Array.from(this.data).forEach(item => {
      if (item.ean.includes(searchString)) {
        this.result.push(item)
      }
      else if (item.ref && item.ref.includes(searchString)){
        this.result.push(item)
      }
    })
  }

  created() {
    this.updateItems()
  }

  async mounted() {
    this.loginService.login().then(() => {
      this.$emit('loggedIn', true);
      this.loggedIn = true;
    }).catch((errorMessage) => {
      console.log(errorMessage);
    })
  }

  login() {
    this.loginService.login().then(() => {
      this.$emit('loggedIn', true);
      this.loggedIn = true;
    }).catch((errorMessage) => {
      console.log(errorMessage);
    })
  }

  updateItems() {
    const apiURLRoot = window.location.protocol + "//" + window.location.host
    fetch(apiURLRoot + "/api/v1/products")
    .then(response => response.json())
    .then(response => {
        this.data = response;
        this.result = this.data;
    }).catch(err => {
        console.log(err);
    });
  }

  itemSelected (item) {
    this.selectedItem = item
  }

  deleteItem (item) {
    const apiURLRoot = window.location.protocol + "//" + window.location.host
    fetch(apiURLRoot + "/api/v1/products/" + item.ean, {
            method: 'DELETE',
            credentials: "include",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            }
    })
    .then(() => this.updateItems())
    .catch(err => {
        console.log(err);
    });
  }

  logout() {
    this.loggedIn = false
    this.$emit('loggedIn', false);
    this.loginService.logout()
  }

  isLoggedIn (loggedIn) {
    this.loggedIn = loggedIn
  }

  back () {
    this.selectedItem = null
  }

  itemSaved () {
    this.updateItems()
    this.selectedItem = null
  }

  newProduct () {
    this.selectedItem = {ean:"", atlas:"", photo:"", files:[]}
  }


}
</script>

<style>
  .administration-title {
     display: flex;
     justify-content: center;
  }
</style>
