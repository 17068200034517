<template>
  <header>
    <img src="../assets/logo_inspire.png" alt="Inspire brand logo"/>
    <img src="../assets/logo_lexman.png" alt="Lexman brand logo"/>
    <img src="../assets/logo_zeiger.png" alt="Zeiger brand logo"/>
  </header>
</template>

<script>
export default {
  name: 'Header'
}
</script>

<style scoped>
header {
  padding: 60px 30px;
  background-color: #dee2e6;
  display:flex;
  justify-content: space-around;
}
header img {
  max-height: 120px;
  margin-left: 10px;
  margin-right: 10px;
  object-fit: scale-down;
}
@media (max-width: 968px) {
  header {
    padding: 30px 15px;
    flex-wrap: wrap;
  }
  header img {
    max-height: 80px;
    margin-bottom: 20px;
    object-fit: scale-down;
  }
}

</style>
